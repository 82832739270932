.value-card {
    background-color: $white-900;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
    height: 70vh;

    h6 {
        color: $gray-500;
        font-size: $font-size-12;
    }

    .bg-indigo {
        background: $indigo-500;
    }
}

.list {
    overflow-y: scroll;
    height: 45vh;
}

::-webkit-scrollbar {
    height: 8px;
    overflow: visible;
}

.data-add {
    display: flex;

    .form-control {
        width: 100%;
        padding-right: 2.375rem;
        margin-right: 2rem;
        margin-bottom: 0;
    }

    button {
        right: 0.5rem;
        top: 0.2rem;
        background-color: $white-900;
        border-color: $blue-500;
        border-radius: 3px;
        color: $blue-500;
        margin-bottom: 0;
    }
}

.editField {
    display: flex;
    align-items: center;

    .form-control {
        font-size: $font-size-12;
        margin-bottom: 0 !important;
    }

    input {
        display: flex;
        width: 9rem;
        margin-left: 0.2rem;
        margin-bottom: 3rem;
    }

    i {
        margin-left: 1rem;
    }

    .add {
        color: $blue-500 !important;
        font-size: $font-size-12;
        padding-left: 0.2rem;
        margin-left: 0.4rem;
        margin-top: 0.3rem;
        cursor: pointer;
    }
    .clear {
        color: $red-500 !important;
        font-size: $font-size-12;
        margin-left: 0.4rem;
        margin-top: 0.3rem;
        cursor: pointer;
    }
}

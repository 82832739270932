.ng-custom {
    border: 0.06rem solid $gray-400;
    border-radius: 0.25rem;

    .ng-select-container {
        border: none;
        box-shadow: none;
        border-radius: $border-radius !important;

        .ng-value-container {
            .ng-input {
                input {
                    vertical-align: middle;
                    font-size: $font-size-14;
                }
            }
            .ng-value {
                padding-left: 1rem;
            }
            .ng-placeholder {
                width: 100%;
                padding-top: 0.2rem;
                color: $gray-400;
                font-size: $font-size-14;
            }

            .ng-placeholder::before {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' fill='rgb(152,162,179)' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'/%3E%3C/svg%3E");
                padding-right: 0.5rem;
            }
        }

        .ng-arrow-wrapper {
            display: none;
        }
    }

    &.ng-select-focused:not(.ng-select-opened) {
        .ng-select-container {
            box-shadow: none;
        }
    }

    .ng-dropdown-panel {
        margin-top: 0.625rem;
        border-radius: $border-radius !important;

        .ng-dropdown-panel-items {
            background-color: $white-900 !important;
            border-radius: $border-radius !important;
        }

        .ng-option {
            display: flex;
            justify-content: space-between;

            &.ng-option-marked,
            &.ng-option.ng-option-selected {
                background-color: $gray-100 !important;

                .ng-option-label {
                    font-weight: $font-weight-normal;
                }
            }

            .ng-option-label {
                font-weight: $font-weight-normal;
            }

            &:hover {
                background: $gray-200 !important;
            }
        }

        .ng-optgroup {
            &.ng-option-marked,
            &.ng-option.ng-option-selected {
                background-color: $gray-50;

                .ng-option-label {
                    font-weight: $font-weight-500;
                }
            }

            .ng-option-label {
                font-weight: $font-weight-500;
            }
        }
    }

    .ng-has-value {
        .ng-placeholder {
            display: none;
        }
    }

    ::-webkit-scrollbar {
        width: 0.1875rem;
        right: 0.125rem;
    }

    ::-webkit-scrollbar-thumb {
        background: $gray-300;
        border-radius: 1rem;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: $gray-400;
    }
}

.nav {
    &-web {
        margin-top: 0rem;
        height: auto;
        width: 100%;
        border: 1px solid $gray-200;
        border-width: 0 0 1px 0;
        background: #ffffff;
        position: sticky;
        top: 0;
        z-index: 98;


        &.action-header {
            z-index: 96;
        }
    }
    &-head{
        border-width: 0 0 0 0;
    }
    &-expanded {
        width: 84%;
    }

    &-collapsed {
        width: 95%;
    }
}

.navbar {
    height: 50 px;
    margin-top: -29 px;
}

.summary-card {
    background-color: $white-900;
    border-left: 3px solid;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);

    h6 {
        color: $gray-500;
    }

    &.summary-card-primary {
        border-color: $primary;
    }

    &.summary-card-success {
        border-color: $success;
    }

    &.summary-card-warning {
        border-color: $warning;
    }

    &.summary-card-secondary {
        border-color: $secondary;
    }

    &.summary-card-danger {
        border-color: $danger;
    }

    &.summary-card-info {
        border-color: $info;
    }

    &.summary-card-indigo {
        border-color: $indigo-500;
    }

    .bg-indigo {
        background: $indigo-500;
    }
}

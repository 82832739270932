.profile-name {
    font-size: $font-size-24;
    @include media-breakpoint-down(lg) {
        font-size: $font-size-20;
    }
}

.profile-email {
    font-size: $font-size-16;
    @include media-breakpoint-down(lg) {
        font-size: $font-size-14;
    }
}

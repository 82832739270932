.dropdown-menu {
    &.profile {
        top: 0.625rem;
        right: -1.875rem !important;
        width: 25.75rem;
    }

    &.about {
        top: 0.625rem;
        right: -5.625rem !important;
        width: auto;
    }

    &-wrapper {
        form {
            width: 100%;
            display: block;
        }
        .highlight {
            .ng-custom {
                background-color: #deefff;
                border-radius: 0.25rem;
            }
        }
    }
}

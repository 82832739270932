.side-modal-wrapper {
    &.modal-dialog {
        width: 30%;
        @include media-breakpoint-down(md) {
            width: auto;
        }
        max-width: none;
        height: 100%;
        margin: 0;
        margin-left: auto;
        transform: translate3d(0%, 0, 0) !important;
    }

    .modal-content {
        min-height: 100%;
        border: 0;
        border-radius: 0;
        background-color: $white-900;

        .modal-body {
            padding: 0rem;
            margin-top: 10vh;
            .modal-form {
                height: 80vh;
            }
        }
    }
}

.service-preview {
    height: 20vh !important;
}

.service-preview-with-data {
    height: 40vh !important;
}

.center-modal-wrapper {
    &.modal-dialog {
        margin-top: 15vh;
        min-width: 70%;
        min-height: 80vh;
        transform: translate3d(0%, 0, 0) !important;
    }

    .modal-content {
        min-height: 100%;
        border: 0;
        border-radius: 0;
        background-color: $white-900;

        .modal-body {
            padding: 0rem;
            margin-top: 10vh;
        }
    }
}

.export-list-modal-wrapper {
    &.modal-dialog {
        margin-top: 25vh;
        min-width: 30%;
        min-height: 50vh;
        transform: translate3d(0%, 0, 0) !important;
    }

    .modal-content {
        min-height: 100%;
        border: 0;
        border-radius: 0;
        background-color: $white-900;

        .modal-body {
            padding: 0rem;
            margin-top: 10vh;
        }
    }
}

.sign-modal-wrapper {
    &.modal-dialog {
        margin-top: 15vh;
        min-width: 30%;
        min-height: 80vh;
        transform: translate3d(0%, 0, 0) !important;
    }

    .modal-content {
        min-height: 100%;
        border: 0;
        border-radius: 10px;
        background-color: $white-900;

        .modal-body {
            padding: 0rem;
            margin-top: 10vh;
        }
    }
}

@media only screen and (min-device-width: 1025px) and (max-device-width: 2280px) {
    .sign-modal-wrapper {
        &.modal-dialog {
            margin-top: 15vh;
            min-width: 50%;
            min-height: 80vh;
            transform: translate3d(0%, 0, 0) !important;
        }

        .modal-content {
            min-height: 100%;
            background-color: $white-900;

            .modal-body {
                padding: 0rem;
                margin-top: 10vh;
            }
        }
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .sign-modal-wrapper {
        &.modal-dialog {
            margin-top: 15vh;
            min-width: 80%;
            min-height: 80vh;
            transform: translate3d(0%, 0, 0) !important;
        }

        .modal-content {
            min-height: 100%;
            background-color: $white-900;

            .modal-body {
                padding: 0rem;
                margin-top: 10vh;
            }
        }
    }
}

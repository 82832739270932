.tooltip {
    position: absolute;
}

.tooltip-arrow {
    position: absolute;
}

.tooltip-wide {
    .tooltip-inner {
        @include media-breakpoint-up(lg) {
            min-width: 25rem !important;
        }
    }
}

.popover {
    position: absolute;
}

.popover-arrow {
    position: absolute;
}


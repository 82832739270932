.accordion-custom {
    .accordion-item {
        .panel,
        .card {
            border: none;

            .panel-heading,
            .card-header {
                background-color: $white-900;
                border: none;
                padding: 1rem;
            }

            .panel-collapse,
            .collapse {
                .panel-body,
                .card-body {
                    padding: 0;
                }
            }
        }
    }
}

.toggle-icon {
    -webkit-text-stroke: 1px;
}

.drag-n-drop-list {
    background: $white-900;
    overflow: hidden;
}

.drag-n-drop-box {
    border: solid 1px $gray-300;
    cursor: move;
    background: $white-900;
    position: relative;
    z-index: 1600 !important;
}

.cdk-drag-preview {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
    opacity: 0;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-box:last-child {
    border: none;
}

.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

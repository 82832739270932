.fw {
    &-normal {
        font-weight: $font-weight-400;
    }
    &-medium {
        font-weight: $font-weight-500;
    }
    &-semibold {
        font-weight: $font-weight-600;
    }
}

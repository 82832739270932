table {
    &.dataTable {
        &.non-empty {
            .dataTables_empty {
                display: none;
            }
        }

        thead {
            th,
            td {
                color: $gray-500;
                font-weight: $font-weight-600;
                font-size: $font-size-14;
                padding: $table-cell-padding-y $table-cell-padding-x !important;
                width: auto !important;
                border-left: none;
                border-right: none;

                &:first-child {
                    padding-left: $table-cell-padding-x * 3 !important;
                }

                &:last-child {
                    padding-right: $table-cell-padding-x * 3 !important;
                }

                &.sorting {
                    &::before {
                        opacity: 0.4 !important;
                    }

                    &::after {
                        opacity: 0.4 !important;
                    }
                }

                &.sorting_asc {
                    &::before {
                        opacity: 1 !important;
                    }
                }

                &.sorting_desc {
                    &::after {
                        opacity: 1 !important;
                    }
                }
            }
        }

        tbody {
            tr {
                &:not(:last-child) {
                    td {
                        border: none;
                    }
                }

                &:last-child {
                    td {
                        border-left: none;
                        border-right: none;
                    }
                }
            }

            td {
                color: $gray-800;
                font-weight: $font-weight-300;
                font-size: $font-size-14;

                &:first-child {
                    padding-left: $table-cell-padding-x * 3 !important;
                }

                &:last-child {
                    padding-right: $table-cell-padding-x * 3 !important;
                }

                &.datatable-action-cell {
                    span {
                        cursor: pointer;
                        font-size: $font-size-16;
                        color: $gray-500;

                        &.cell-action {
                            &-edit {
                                &:hover {
                                    color: $primary;
                                }
                            }

                            &-delete {
                                &:hover {
                                    color: $danger;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// data-table overrides
.dataTables_wrapper {
    .dataTables {
        &_info {
            color: $gray-500;
            font-weight: $font-weight-400;
            font-size: $font-size-14;
            padding-top: 0 !important;
        }

        &_length {
            color: $gray-700;
            font-weight: $font-weight-400;
            font-size: $font-size-14;

            .form-select {
                background-color: $white-900;
                color: $gray-600;
            }
        }

        &_paginate {
            font-size: $font-size-14;

            .page-link {
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.5rem;
                line-height: 1rem;
                min-width: 2rem;
                height: 2rem;
            }

            .page-item {
                margin-left: 0.5rem;

                a {
                    border: none;
                }

                &.next,
                &.previous {
                    a {
                        border: $pagination-border-width solid $pagination-border-color;
                        background-color: $gray-200;
                    }
                }
            }
        }
    }
}

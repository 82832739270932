.status-complete {
    background-color: $blue-300;
    color: $blue-800;
}

.status-todo {
    background-color: $yellow-300;
    color: $orange-600;
}

.card{
    border-color: $gray-400;
    border-width: 1px;
}
.select-input-custom {
    .ng-select-container {
        border-radius: 0.25rem;
        border-color: $gray-300;
    }
    &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
        border-color: rgb(144, 160, 194);
    }

    &.is-invalid {
        &.ng-touched {
            .ng-select-container {
                border-color: $danger;
                padding-right: calc(1.5em);
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
                background-repeat: no-repeat;
                background-position: right calc(0.375em + 0.1875rem) center;
                background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
            }
        }
    }
}

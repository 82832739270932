.btn {
    &.btn-transparent {
        background: transparent;
        border: none;
        color: $primary;
        &:hover {
            color: $blue-700;
        }
    }
}

.check-list-btn {
    background-color: $blue-100;
}

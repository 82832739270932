.custom-breadcrumb {
    .breadcrumb-item {
        color: $secondary;
        &.active {
            color: $gray-900;
        }
        a {
            text-decoration: none;
        }
    }
}

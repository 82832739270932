// fonts
@import '~@fontsource/inter';
@import '@fontsource/inter/400.css';
@import '@fontsource/inter/500.css';
@import '@fontsource/inter/600.css';
@import '@fontsource/inter/700.css';

// overrides
@import 'abstract/abstract-index';

// bootstrap
@import '~bootstrap/scss/bootstrap';
// bootstrap-icons
@import '~bootstrap-icons/font/bootstrap-icons.css';
// datatables
@import '~datatables.net-bs5/css/dataTables.bootstrap5.min.css';
@import '~datatables.net-buttons-dt/css/buttons.dataTables.css';
// ng-select
@import '~@ng-select/ng-select/themes/default.theme.css';
// ngx-bootstrap-date-picker
@import '~ngx-bootstrap/datepicker/bs-datepicker.css';

// customizations\
@import 'layout/layout-index';
@import 'common/common-index';
@import 'component/component-index';

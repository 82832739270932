// colors
// blue
$blue-100: #dbeafe;
$blue-300: #93c5fd;
$blue-600: #2563eb;
$blue-700: #1d4ed8;
$blue-800: #1e40af;
$blue-900: #1e3e82;

// --gray
$gray-25: #fcfcfd;
$gray-50: #f9fafb;
$gray-100: #f2f4f7;
$gray-200: #f8fafc;
$gray-300: #d0d5dd;
$gray-400: #98a2b3;
$gray-500: #64748b;
$gray-600: #6c757d;
$gray-700: #344054;
$gray-800: #1d2939;
$gray-900: #101828;

// --white
$white-900: #fff;

// --orange
$orange-400: #fb923c;
$orange-600: #ca6510;

// --green
$green-600: #10b981;

// --red
$red-600: #ef4444;

// --yellow
$yellow-300: #ffda6a;
$yellow-600: #eab308;

// typography
// --font-weights
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;

// --font-sizes
$font-size-base-custom: 1rem;
$font-size-10: $font-size-base-custom * 0.625;
$font-size-12: $font-size-base-custom * 0.75;
$font-size-14: $font-size-base-custom * 0.875;
$font-size-16: $font-size-base-custom;
$font-size-18: $font-size-base-custom * 1.125;
$font-size-20: $font-size-base-custom * 1.25;
$font-size-24: $font-size-base-custom * 1.5;
$font-size-26: $font-size-base-custom * 1.75;
$font-size-80: $font-size-base-custom * 5;

// box-shadow
// --pagination
$box-shadow-pagination: 0 0 0 0.25rem $gray-200;

.fs {
    &-80 {
        font-size: $font-size-80;
    }

    &-26 {
        font-size: $font-size-26;
    }

    &-24 {
        font-size: $font-size-24;
    }

    &-20 {
        font-size: $font-size-20;
    }

    &-18 {
        font-size: $font-size-18;
    }

    &-16 {
        font-size: $font-size-16;
    }

    &-14 {
        font-size: $font-size-14;
    }

    &-12 {
        font-size: $font-size-12;
    }

    &-10 {
        font-size: $font-size-10;
    }
}

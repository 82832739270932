.form-control {
    background-color: $white-900;
    border-color: $gray-300;

    &:focus {
        background-color: $white-900;
    }
}

.has-search {
    position: relative;

    .form-control {
        padding-right: 2.375rem;
    }

    i {
        position: absolute;
        right: 0.5rem;
        top: 0.2rem;
        color: $gray-500;
    }

    .form-control-feedback {
        position: absolute;
        z-index: 2;
        display: block;
        width: 2.375rem;
        height: 2.375rem;
        line-height: 2.375rem;
        text-align: center;
        pointer-events: none;
        color: $gray-500;
        font-size: $lead-font-size;
    }

    &.typehead-search {
        .form-control {
            padding-left: 2rem;
        }
    }
}

//start: date Range calendar css

.bs-datepicker {
    flex-direction: row-reverse;
    margin-bottom: 5rem;
}

// theme override
.theme-dark-blue .bs-datepicker-head {
    background-color: $blue-900 !important;
}

.theme-dark-blue .bs-datepicker-predefined-btns button.selected {
    background-color: $blue-900 !important;
}

.theme-dark-blue .bs-datepicker-body table td span.selected,
.theme-dark-blue .bs-datepicker-body table td.selected span,
.theme-dark-blue .bs-datepicker-body table td span[class*='select-']:after,
.theme-dark-blue .bs-datepicker-body table td[class*='select-'] span:after {
    background-color: $blue-900 !important;
}

.theme-dark-blue .bs-datepicker-body table td.week span {
    color: $blue-900 !important;
}

.theme-dark-blue .bs-datepicker-body table td.active-week span:hover {
    cursor: pointer;
    background-color: $blue-900;
    color: #fff;
    opacity: 0.5;
    transition: 0s;
}

.bs-datepicker-custom-range {
    padding: 15px;
    background: transparent;
    background-color: $white-900 !important;
    button{
        background-color: $blue-900 !important;
    }
}
.bs-datepicker-predefined-btns {
    button:hover {
        color: white;
        background: $primary;
    }
}

@include media-breakpoint-down(md) {
    .date-range-wrapper {
        width: 100% !important;
    }
}
//end: date range calandar

.has-date-filter {
    position: relative;

    .form-control {
        padding-right: 2.375rem;
        // width: max-content;
    }

    i {
        position: absolute;
        right: 0.5rem;
        top: 0.2rem;
        color: $gray-500;
    }

    .form-control-feedback {
        position: absolute;
        z-index: 2;
        display: block;
        width: 2.375rem;
        height: 2.375rem;
        line-height: 2.375rem;
        text-align: center;
        pointer-events: none;
        color: $gray-500;
        font-size: $lead-font-size;
    }

    select option {
        margin-top: 40px;
        width: 2.375rem;
        height: 2.375rem;
        border-radius: 0.2rem;
    }

    .ng-select .ng-select-container {
        font-size: 0.9rem !important;
        min-height: 20px;
        margin-bottom: 1.5rem !important ;
    }

    .ng-select.ng-select-single .ng-select-container {
        height: 32px;
    }

    &.typehead-search {
        .form-control {
            padding-left: 2rem;
        }
    }
}

.text-area-character-limit {
    position: absolute;
    bottom: 0.625rem;
    right: 0.625rem;
}

.sidebar {
    position: relative;
    z-index: 110;

    &-container {
        min-height: 100vh;
        background-color: $blue-900;
        padding: 0;
        overflow: auto;
        max-height: 100vh;
        overflow: scroll;
    }

    ::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }

    /* Sidebar sizes when expanded */
    &-expanded {
        width: 242px;
        position: fixed;
        z-index: 1;
        transition: 0.3s;

        /* Menu item*/
        .list-group {
            &-item {
                & .menu-collapsed {
                    display: block;
                    margin-left: 0.5rem;
                }
            }
            .sub-item {
                .items {
                    margin-left: 2.2rem;
                    padding-bottom: 16px;
                }
                & .menu-collapsed {
                    display: block;
                    margin-left: 0.5rem;
                }
            }
        }
    }

    &-collapsed {
        width: 60px;
        position: fixed;
        z-index: 1;
        transition: 0.3s;

        /* Menu item*/
        .list-group {
            &-item {
                height: 64px;
                & .menu-collapsed {
                    display: none;
                }
            }
            .sub-item {
                .items {
                    margin-left: 0;
                }
                & .menu-collapsed {
                    display: none;
                }
            }
        }
    }

    &-space {
        margin-left: 0;
        width: auto;
        // border-top: 1px solid $amp-app-border;
        @include media-breakpoint-up(lg) {
            margin-left: 60px;
        }

        &.expanded {
            margin-left: 60px;
            transition: 0.3s;

            @include media-breakpoint-up(lg) {
                margin-left: 242px;
            }
        }

        &.collapsed {
            transition: 0.3s;
        }
    }

    .list-group {
        min-height: 76vh;

        &-item {
            background-color: $blue-900;
            border: none;
            font-size: 14px;
            line-height: 20px;
            font-weight: $font-weight-400;
            color: $white-900;
            padding: 20px 16px;

            .bi {
                font-size: 16px;
            }

            &.active {
                background-color: $blue-700;
                font-weight: $font-weight-600;
            }

            &:hover {
                background-color: $blue-700;
                font-weight: $font-weight-600;
            }
        }

        &.disabled:hover {
            background-color: $white-900;
            font-weight: $font-weight-600;
        }

        .sub-item {
            background-color: $blue-900;
            font-size: 14px;
            line-height: 20px;
            font-weight: $font-weight-400;
            color: $white-900;
            text-decoration: none;

            & .box {
                padding-top: 1rem;
                &.active {
                    border-left-style: solid;
                    font-weight: $font-weight-600;
                }
            }

            .bi {
                font-size: 16px;
            }

            &:hover {
                background-color: $blue-700;
                font-weight: $font-weight-600;
            }
        }

        .dropdown {
            min-height: 0vh;
            background-color: $blue-900;
            border: none;
            font-size: 14px;
            line-height: 20px;
            font-weight: $font-weight-400;
            color: $white-900;
            padding: 15px 16px;
            &-expanded {
                display: block;
                border-left-style: solid;
                border-left-color: $white-900;
            }
            &-collapsed {
                display: none;
            }

            .bi {
                font-size: 16px;
            }

            &.active {
                background-color: $blue-700;
                font-weight: $font-weight-600;
            }
        }

        &.mobile {
            padding-top: 14px;
            &.fade-in {
                visibility: visible;
                -webkit-animation: fade-in-fwd 1.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
                animation: fade-in-fwd 1.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
                @-webkit-keyframes fade-in-fwd {
                    0% {
                        -webkit-transform: translateZ(-80px);
                        transform: translateZ(-80px);
                        opacity: 0;
                    }
                    100% {
                        -webkit-transform: translateZ(0);
                        transform: translateZ(0);
                        opacity: 1;
                    }
                }
                @keyframes fade-in-fwd {
                    0% {
                        -webkit-transform: translateZ(-80px);
                        transform: translateZ(-80px);
                        opacity: 0;
                    }
                    100% {
                        -webkit-transform: translateZ(0);
                        transform: translateZ(0);
                        opacity: 1;
                    }
                }
            }
            &.fade-out {
                opacity: 0;
            }
        }
    }
}

.mobile-sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 110;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.3s;
    background-color: $blue-900;
    &.mobile-expanded {
        @include media-breakpoint-down(md) {
            width: 65%;
        }
        @include media-breakpoint-down(SM) {
            width: 35%;
        }

        /* Menu item*/
        .list-group {
            &-item {
                & .menu-collapsed {
                    display: block;
                    margin-left: 0.5rem;
                }
            }
        }
    }
    a {
        transition: 0.3s;
    }
}

.off-overlayy {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.logo-expanded {
    width: 150px;
}

.logo-collapsed {
    width: 40px;
}

.sidebar-toggle {
    color: $gray-200;
}

.list-group-item {
    &:first-child {
        border-radius: 0;
    }

    &:last-child {
        border-radius: 0;
    }
}
